import { list, setPopupStatus } from "@/apis/card";
import { addCommas } from "@/utils/utils";
import { getInfo } from "@/apis/member";
import request from "@/utils/request";
import { showDialog } from "@/utils/dialog";
const user = {
  namespaced: true,
  state: {
    bonus: 0,
    bonusText: "0",
    userinfo: null,
    tokenKey: "token"
  },
  mutations: {
    SET_BONUS(state, data) {
      state.bonus = data;
    },
    SET_BONUSTEXT(state, data) {
      state.bonusText = data;
    },
    SET_USERINFO(state, data) {
      state.userinfo = data;
    },
    SET_TOKENKEY(state, data) {
      state.tokenKey = data;
    }
  },
  actions: {
    setBonus({ commit, state }, data) {
      if (!state.userinfo) return;
      list().then((res) => {
        // 原本取得點數的流程
        const cardList = res.cardList || [];
        const defaultItem = cardList.find((item) => item.isDefault);
        const availableItem = cardList.find((item) => item.status === 3);

        if (defaultItem && defaultItem.status === 3) {
          commit("SET_BONUS", defaultItem.bonus);
          commit("SET_BONUSTEXT", addCommas(defaultItem.bonus));
        } else {
          commit("SET_BONUSTEXT", addCommas("-"));
        }

        if (!data.checkCard) return;

        // 處理退款卡片邏輯
        if (!availableItem && res.popupStatus === 1) {
          showDialog({
            title: "提醒您",
            message: "目前您的帳號內無點點卡，\r\n請至我的專區新增點點卡。",
            messageStyle: { fontWeight: "bold" },
            checkbox: "不要再通知我",
            hideCancel: true,
            confirmText: "確認",
            onConfirm({ checked }) {
              if (res.popupStatus !== Number(!checked)) {
                setPopupStatus(Number(!checked));
              }
            }
          });
        } else if (availableItem) {
          if (!defaultItem || defaultItem.status !== 3) {
            showDialog({
              title: "提醒您",
              message:
                "目前您的帳號內無預設點點卡，若須使用點數商城功能，請至我的專區選取並預設一張點點卡",
              hideCancel: true,
              onConfirm() {
                location.href = `${
                  process.env.VUE_APP_COOLBE_LIFF_URL
                }/member-card/card-select?redirect-uri=${encodeURIComponent(
                  location.href
                )}`;
              }
            });
          } else if (res.popupStatus !== 1) {
            setPopupStatus(1);
          }
        }
      });
    },
    async getUserInfo({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (localStorage.getItem(state.tokenKey)) {
          getInfo().then((res) => {
            commit("SET_USERINFO", res);
            dispatch("setBonus", {
              checkCard: location.pathname.includes("/pointShop")
            });
            resolve();
          });
        } else {
          reject("無token");
        }
      });
    },
    setTokenKey({ commit }, data) {
      commit("SET_TOKENKEY", data);
      request.defaults.headers.accessToken = localStorage.getItem(data);
    }
  },
  getters: {
    hasSetTransPwd: (state) => {
      return !!state.userinfo?.bindTransPwd;
    },
    phone: (state) => {
      return state.userinfo?.phone;
    }
  }
};

export default user;
