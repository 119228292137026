import request from "@/utils/request";
import JSEncrypt from "jsencrypt";
import { generateRandomString } from "@/utils/utils";
import store from "@/store";

export function carousel(data) {
  return request.post("/common/carousel", data);
}
/**
 * 搜索熱詞
 *
 * @export
 * @param {*} data
 *  category	string 分類 Enum: [ Store, ItemCoupon ]

 * @return {*}
 */
export function searchHotWord(data) {
  return request.post("/common/searchHotWord", data);
}
/**
 *
 * 捐贈發票機構
 * @export
 * @param {*} data
 * @return {*}
 */
export function invoiceOrg(data = {}) {
  return request.post("/common/invoiceOrg", data);
}

/**
 * 查詢使用條款詳情
 *
 * @export
 * @param {*} [data={}]
 *  type	string 使用条款类型 Enum: [ Privacy_Statement, Member_Promotion_Terms, Instructions_For_Use, Instructions_For_Use_Process, Transfer_Notice, Return_Notice, Point_To_Boun_Notice, Lose_Card_Notice, Notes_For_Internet_Use, Refund_Clause, Delete_Member, Email_Valid ]
 * @return {*}
 */
export function instruction(data = {}) {
  return request.post("/common/instruction", data);
}

/**
 * 獲取訪問密鑰
 *
 * @export
 * @param {*} [data={
 * lineId
 * }]
 * @return {*}
 */
export function secretKey(data = {}) {
  const aesKey = generateRandomString(24);
  const publicKey = process.env.VUE_APP_DEFAULT_RSA;
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const rsaAesKey = encrypt.encrypt(aesKey);
  store.dispatch("keystone/setAesKey", aesKey);
  return request.post("/common/secretKey", data, {
    headers: {
      mcdS1: rsaAesKey
    }
  });
}

/**
 * 加解密測試
 *
 * @export
 * @param {*} [data={}]
 * @return {*}
 */
export function encryptDemo(data = {}) {
  return request.post("/common/encryptDemo", data);
}

export function createLog(tags = "", values = {}) {
  return request.post("/mtelapi/v1/log/create", {
    logMsg: `[FRONTEND LOG]${tags}: ${JSON.stringify(values)}`
  });
}
