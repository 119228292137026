import { createApp, h, ref } from "vue";
import { Popup } from "vant";
import commonBtn from "@/components/commonBtn";

export function showDialog({
  title,
  message,
  messageStyle = {},
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  hideCancel,
  checkbox
}) {
  const dialogWrapper = document.createElement("div");
  document.body.appendChild(dialogWrapper);
  return new Promise((resolve, reject) => {
    const app = createApp({
      data() {
        return {
          isShow: true,
          checked: false
        };
      },
      methods: {
        handleConfirm() {
          onConfirm && onConfirm({ checked: this.checked });
          this.isShow = false;
          return resolve(true);
        },
        handleCancel() {
          onCancel && onCancel();
          this.isShow = false;
          return reject("cancel");
        }
      },
      render() {
        let bottomBtn = [];
        if (hideCancel) {
          bottomBtn = [
            h(commonBtn, {
              text: confirmText || "確認",
              style: { width: "100%" },
              onClick: this.handleConfirm
            })
          ];
        } else {
          bottomBtn = [
            h(commonBtn, {
              text: cancelText || "取消",
              style: {
                width: "48%",
                background: "#CCCCCC",
                lineHeight: "35px"
              },
              onClick: this.handleCancel
            }),
            h(commonBtn, {
              text: confirmText || "確認",
              style: { width: "48%", lineHeight: "35px" },
              onClick: this.handleConfirm
            })
          ];
        }
        return h(
          Popup,
          { show: this.isShow, round: true, style: { width: "80%" } },
          {
            default: (props) =>
              h(
                "div",
                {
                  style: {
                    padding: "20px",
                    margin: "0",
                    boxSizing: "border-box"
                  }
                },
                [
                  h(
                    "h4",
                    {
                      style: {
                        fontSize: "17px",
                        fontWeight: "bold",
                        margin: "5px 0",
                        textAlign: "center",
                        whiteSpace: "break-spaces"
                      }
                    },
                    title
                  ),
                  h(
                    "p",
                    {
                      style: {
                        fontSize: "13px",
                        lineHeight: "18px",
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                        ...messageStyle
                      }
                    },
                    message
                  ),
                  ...(checkbox
                    ? [
                        h(
                          "div",
                          {
                            style: {
                              margin: "30px 0 10px 0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "4px"
                            }
                          },
                          [
                            h("input", {
                              type: "checkbox",
                              style: {
                                cursor: "pointer"
                              },
                              id: "dialog-checkbox",
                              checked: this.checked,
                              onChange: (e) => {
                                this.checked = e.target.checked;
                              }
                            }),
                            h(
                              "label",
                              {
                                style: {
                                  fontSize: "13px",
                                  cursor: "pointer"
                                },
                                for: "dialog-checkbox"
                              },
                              checkbox
                            )
                          ]
                        )
                      ]
                    : []),
                  h(
                    "div",
                    {
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }
                    },
                    bottomBtn
                  )
                ]
              )
          }
        );
      }
    });

    app.mount(dialogWrapper);
  });
}
