import request from "@/utils/request";

/**
 * 獲取會員卡列表
 *
 * @export
 * @param {*} [data={}]
 * @return {*}
 */
export function list(data = {}) {
  return request.post("/card/list", data);
}

/**
 * 查詢 點數商城,預購寄杯 訂單記錄
 *
 * @export
 * @param {*} [data={}]
 * active	string 狀態【3:PaySuccess=訂單完成，4:PayFail=訂單失效，5/7:Refunding/RefundFail=退貨處理中，6：RefundSuccess=退貨/退款】

    Enum:
      [ Init, ToPaid, Processing, PaySuccess, PayFail, Refunding, RefundSuccess, RefundFail ]
    paymentType	integer($int32)
  支付方式【1=點數商城(0:點數，1:點數加價)，2=隨買店取(2:現金)】
 * @return {*} 
 */
export function orderList(data = {}) {
  return request.post("/item/orderList", data);
}

/**
 * 查詢點數記錄訂單明細
 *
 * @export
 * @param {*} [data={}]
 * lmsInvoiceNo	string
交易序號

orderId	integer($int64)
訂單ID
 * @return {*}
 */
export function getOrderDetail(data = {}, config = {}) {
  return request.post("/item/orderDetail", data, config);
}
export function setPopupStatus(popupStatus = 0) {
  return request.put("/card/setPopupStatus", { popupStatus });
}
