import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Popup, Icon, Picker, Dialog, Radio, RadioGroup } from "vant";
import "normalize.css";
import "vant/lib/index.css";
import "animate.css/animate.min.css";
import liff from "@line/liff";
import eruda from "eruda";
import {
  getQueryString,
  generateRandomString,
  isNeedLiff
} from "@/utils/utils";
import * as Sentry from "@sentry/vue";
import coolbeApi from "./apis/coolbe";

let isDebug =
  localStorage.getItem("debug") == "true" ||
  getQueryString("debug") == "true" ||
  process.env.NODE_ENV == "development";
let isSkipLineLogin = getQueryString("skipLineLogin");

if (
  (import.meta.env.MODE === "qa" ||
    import.meta.env.MODE === "qa-java" ||
    isDebug) &&
  import.meta.env.MODE !== "production"
) {
  eruda.init();
  window.liff = liff;
}

const handleCoolbeTag = async () => {
  try {
    const memberRes = await coolbeApi.getSysAvailableToken();

    const memberId = memberRes.member_id;
    const pathname = location.pathname;
    const seqMap =
      coolbeApi.SEQ_MAP[import.meta.env.MODE === "production" ? "prod" : "uat"];
    if (pathname === "/pick") {
      if (memberId) {
        coolbeApi.saveTag(seqMap.pick_bind);
      } else {
        coolbeApi.saveTag(seqMap.pick_not_bind);
      }
    } else if (pathname === "/pointShop") {
      if (memberId) {
        coolbeApi.saveTag(seqMap.pointShop_bind);
      } else {
        coolbeApi.saveTag(seqMap.pointShop_not_bind);
      }
    }
  } catch (error) {
    console.error("[ERROR: c3fbc1] error", "src/main.js", error);
  }
};

(async () => {
  try {
    if (isDebug) {
      localStorage.setItem("debug", "true");
    }
    console.log(process.env.VUE_APP_LIFF_ID);
    if (isNeedLiff()) {
      await liff
        .init({ liffId: process.env.VUE_APP_LIFF_ID })
        .then(async () => {});
      await liff.ready.then(async () => {
        console.log("liff ready");
        if (!liff.isLoggedIn()) {
          await liff.login({
            redirectUri: location.href
          });
          return Promise.reject("logining");
        } else {
          const { userId } = await liff.getProfile();
          store.dispatch("user/setTokenKey", userId + "_token");
          await store.dispatch("keystone/setPublicKeyAndRandomId", {
            lineId: userId
          });
          handleCoolbeTag();
        }
      });
    }

    if (
      process.env.NODE_ENV === "qa" ||
      process.env.NODE_ENV === "development"
    ) {
      window.liff = liff;
    }

    store.dispatch("system/setOsVersion");
    store.dispatch("system/setDeviceType");

    console.log("get info");
    store.dispatch("user/getUserInfo"); // 獲取用戶信息

    const app = createApp(App)
      .use(store)
      .use(router)
      .use(Popup)
      .use(Icon)
      .use(Picker)
      .use(Dialog)
      .use(Radio)
      .use(RadioGroup)
      .mount("#app");

    // 開啟sentry
    if (
      isDebug ||
      import.meta.env.MODE === "qa" ||
      import.meta.env.MODE === "uat"
    ) {
      const dsn =
        "https://b3b49997b2554aba60da5ab4dcdc8f70@o4504751268364288.ingest.sentry.io/4505945619431424";
      Sentry.init({
        app,
        dsn,
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              "localhost",
              /^https:\/\/loaecu\.mcddailyapp\.com\.tw/,
              /^https:\/\/mcd-lineoa\.eydnd\.com/,
              /^https:\/\/azphpq\.mtel\.ws/
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
          }),
          new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false
          })
        ],
        environment: import.meta.env.MODE,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  } catch (error) {
    console.error("[ERROR: c39b5a] error", "src/main.js", error);
    if (error.message === "The access token expired") {
      await liff.logout();
      await liff.login({
        redirectUri: location.href
      });
    }
  }
})();
